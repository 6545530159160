<template>
  <div :class="$style.root">
    <VDataTable
        fixed-header
        height="calc(100vh - 263px)"
        :value="value"
        :show-select="!!value"
        :headers="headers"
        :items="listItems"
        :loading="loading"
        :footer-props="footer"
        :options.sync="options"
        :server-items-length="count"
        :item-class="getClassByRow"
        :mobile-breakpoint="0"
        @click:row="({ id }) => onDetail(id)"
        @input="value => $emit('input', value)"
        @update:page="page => onPagination({ page })"
        @update:items-per-page="size => onPagination({ size })"
    >
      <template v-slot:body.prepend>
        <FilterRow :items="headers" @input="onPagination" :show-select="showSelect" />
      </template>
    </VDataTable>
  </div>
</template>

<script>
import FilterRow from '@/components/inspect/FilterRow/FilterRow';
import {isNull, omitBy} from 'lodash-es';

export default {
  name: 'InspectorList',
  components: {
    FilterRow,
  },
  props: {
    headers: { type: Array },
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
    value: { type: Array },
    showSelect: { type: Boolean },
  },
  data: function() {
    return {
      footer: {
        itemsPerPageText: '',
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
      },
      options: {
        page: this.page,
        itemsPerPage: this.size,
      },
    }
  },
  computed: {
    listItems() {
      if (!this.items) return [];
      return this.items;
    }
  },
  methods: {
    getClassByRow() {
      return '';
    },
    onPagination(params) {
      const { query } = this.$route;
      this.$router.push({ query: omitBy({ ...query, ...params }, isNull) });
    },
    onDetail(id) {
      this.$emit('click:row', { id });
    },
  }
}
</script>

<style module lang="scss">
.root {
  th {
    width: 100px !important;
    &:nth-child(1) {
      width: 1px !important;
    }
  }
}
</style>

<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}}</h1>
    <div class="mb-4 clearfix">
      <VBtn depressed @click="$router.push({ name: 'inspect/ApplicationDetailView', params: { application } })">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад
      </VBtn>
    </div>
    <InspectorList
        show-select
        v-model="selected"
        :headers="[
          { text: 'ID', value: 'id', sortable: false },
          { text: 'Имя', value: 'name', sortable: false },
          { text: 'Компания', value: 'company', sortable: false },
          { text: 'Вид инспектора', value: 'type.text', alias: 'type', options: types, sortable: false },
          { text: 'ИНН', value: 'inn', sortable: false },
          { text: 'Почта', value: 'email', sortable: false },
          { text: 'В работе', value: 'work', sortable: false },
          { text: 'Всего инспекций', value: 'inspections', sortable: false },
          { text: 'Рейтинг', value: 'rating', sortable: false },
        ]"
        :items="list.items"
        :count="list.count"
        :page="page"
        :size="size"
        :loading="pending"
    />
    <VFooter v-if="selected.length" app>
      <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" :class="$style.progress" />
      <div class="py-3 grow">
        <VBtn color="primary" class="mr-2" depressed @click="onSave">Добавить</VBtn>
        <VBtn color="secondary" class="mr-2" depressed @click="() => selected = []">Отмена</VBtn>
      </div>
    </VFooter>
  </div>
</template>

<script>
import { debounce, values, map } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { APPLICATION_STATUSES as STATUSES, INSPECTOR_TYPES as TYPES } from '@/store/inspect/enums';
import InspectorList from '@/components/inspect/InspectorList/InspectorList';

export default {
  name: 'ApplicationInspectorBindingListView',
  components: {
    InspectorList,
  },
  props: {
    application: { type: String },
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    ...mapGetters({
      pending: 'inspect/pending',
      list: 'inspect/inspectors',
    }),
    statuses() {
      return values(STATUSES);
    },
    types() {
      return values(TYPES);
    },
  },
  methods: {
    ...mapActions({
      fetchInspectorList: 'inspect/fetchInspectorList',
      inviteInspector: 'inspect/inviteInspector'
    }),
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchInspectorList({ page, size, filter });
    }, 500),
    onSave() {
      const application = this.application;
      const inspectors = map(this.selected, 'id');
      this.inviteInspector({ application, inspectors }).then(() => {
        this.$router.push({ name: 'inspect/ApplicationDetailView', params: { application } });
      })
    }
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.root {}
.progress {
  top: 0;
}
</style>
